import React from 'react';

import Bill from '../Bill'

import './index.css';

function App() {
  return (
    <div id="App">
      <div id='bill-content'>
          <Bill/>
      </div>
    </div>
  );
}

export default App;
