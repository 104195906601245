import styled from 'styled-components'

import { ReactComponent as Loading } from '../../media/loading.svg'

export const StyledLoading = styled(Loading)`
	height: 54px;
	width: 56px;
	margin: 0 auto;

`

export const StyledLogo = styled.div`
    margin-top: 50px;
`

export const StyledDownside = styled.div`
    text-align: center;
    margin-top: 190px;
`

