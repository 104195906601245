import React, { useState, useMemo } from 'react'
import axios from 'axios'
import queryString from 'querystring'

import logo from '../../media/logo.svg'
import disabledLogo from '../../media/disabledLogo.svg'
import errorImg from '../../media/attention.svg'

import { StyledLoading, StyledLogo, StyledDownside } from './styled.js'

const LoadingScreen = ({ text, textColor, logoSrc, isLoading}) => (
    <>
        <StyledLogo>
            <img src={logoSrc} alt=""/>
        </StyledLogo>
        <StyledDownside>
            {
                isLoading
                    ?
                    <StyledLoading/>
                    :
                    <img src={errorImg} alt='errorImg'/>
            }
            <h1 style={{ color: textColor, fontSize: '16px' }}>{text}</h1>
        </StyledDownside>
    </>
)

const Bill = (props) => {
    const requestConfig = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            crossdomain: true ,
            'content-type': 'application/x-www-form-urlencoded'
        }
    }
    const mainUrl = window.location.origin
    //'https://beleg.hssoft.com'//'https://hssoft-delivery.s3.amazonaws.com'
    const dynamicId = (queryString.parse(window.location.search))['?id']
    const isDynamicIdCorrect = !!dynamicId

    const url = `${mainUrl}/${dynamicId}`

    const [isSuccess, setSuccess] = useState(false)
    const [timerId, setTimerId] = useState(null)

    const requestFunc = (timerId) => {
        axios.head(url, requestConfig)
            .then(({ headers }) => {
                if(headers['content-type'] === 'application/pdf') {
                    setSuccess(true)
                }
            })

        if(isSuccess) {
            clearInterval(timerId)
        }
    }

    useMemo(()=> {
        if(isDynamicIdCorrect) {
            setTimerId(setInterval(()=>requestFunc(timerId), 3000 ))
        }
    },[]) //useMemo = componentWillMount

    useMemo( ()=> {
        if(isSuccess){
            clearInterval(timerId)
        }
    }, [isSuccess])

    if(isSuccess) {
        window.location = `https://docs.google.com/viewerng/viewer?url=${url}`
    }


    const printContent = () => {
        if(!isDynamicIdCorrect) {
            return (
                    <LoadingScreen
                        text={'Wrong QR link!'}
                        textColor={'#dd5860'}
                        logoSrc={disabledLogo}
                        isLoading={false}
                    />
            )
        }

        return <LoadingScreen
            text={'Bitte warten...'}
            textColor={'#3c445a'}
            logoSrc={logo}
            isLoading={true}
        /> 
    }
    return (
        <div style={{ textAlign: 'center',  display: 'flex', flexDirection: 'column', height: '100vh', background: '#f8f8f8' }}>
            {printContent()}
        </div>
    )
}


export default Bill

